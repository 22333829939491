import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
    Box, Tabs, Tab, Typography, TextField, Button, Paper, Chip,
    Tooltip, IconButton, Menu, MenuItem, useTheme, useMediaQuery, Grid, Snackbar, Alert
} from '@mui/material';
import {
    ContentCopy as ContentCopyIcon,
    Instagram as InstagramIcon,
    Facebook as FacebookIcon,
    X as XIcon,
    LinkedIn as LinkedInIcon,
    Description as DescriptionIcon,
    Info as InfoIcon,
    Campaign as CampaignIcon,
    Source as SourceIcon,
    Search as SearchIcon,
    FileDownload as FileDownloadIcon,
    Language as LanguageIcon,
    Save as SaveIcon,
    QuestionMark as QuestionMarkIcon,
    Link as LinkIcon,
    OpenInNew as OpenInNewIcon
} from '@mui/icons-material';
import TurndownService from 'turndown';
import { gfm } from 'turndown-plugin-gfm';
import { updateData } from '../services/api';
import TableOfContents from './TableOfContents';

const SocialPost = React.memo(({ platform, initialContent, onSave }) => {
    const [content, setContent] = useState(initialContent);
    const [isSaving, setIsSaving] = useState(false);
    const theme = useTheme();

    const platformIcons = {
        Instagram: <InstagramIcon />,
        Facebook: <FacebookIcon />,
        Twitter: <XIcon />,
        LinkedIn: <LinkedInIcon />,
    };

    const handleContentChange = useCallback((event) => setContent(event.target.value), []);
    const handleCopy = useCallback(() => navigator.clipboard.writeText(content), [content]);

    const handleSave = useCallback(async () => {
        setIsSaving(true);
        try {
            await onSave(platform, content);
            setIsSaving(false);
        } catch (error) {
            console.error(`Error saving ${platform} post:`, error);
            setIsSaving(false);
        }
    }, [platform, content, onSave]);

    return (
        <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                {platformIcons[platform]}
                <Typography variant="h6" sx={{ ml: 1, fontSize: '1rem' }}>{platform}</Typography>
            </Box>
            <TextField
                fullWidth
                multiline
                rows={4}
                value={content}
                onChange={handleContentChange}
                variant="outlined"
                sx={{ mb: 2, '& .MuiOutlinedInput-root': { backgroundColor: theme.palette.action.hover } }}
            />
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body2" color="text.secondary">
                    {content.length} caractères
                </Typography>
                <Box>
                    <Button
                        startIcon={<SaveIcon />}
                        onClick={handleSave}
                        disabled={isSaving}
                        size="small"
                        sx={{ mr: 1 }}
                    >
                        {isSaving ? 'Sauvegarde...' : 'Sauvegarder'}
                    </Button>
                    <Button startIcon={<ContentCopyIcon />} onClick={handleCopy} size="small">
                        Copier
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
});

const extractFaqFromHtml = (htmlContent) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const faqDict = {};
  
  const details = doc.querySelectorAll('details');
  details.forEach(detail => {
    const question = detail.querySelector('summary').textContent.trim();
    const answer = detail.querySelector('div[itemprop="text"]').textContent.trim();
    faqDict[question] = answer;
  });
  
  return faqDict;
};

const ContenusArticles = ({ details, onHeadingClick }) => {
    const [tabValue, setTabValue] = useState(0);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [slug, setSlug] = useState("");
    const [currentContent, setCurrentContent] = useState(details.data.Article.Content);
    const [tags, setTags] = useState([]);
    const [inputTag, setInputTag] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [socialPosts, setSocialPosts] = useState({
        Instagram: "",
        Facebook: "",
        Twitter: "",
        Linkedin: ""
    });
    const [isSaving, setIsSaving] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [rawFaqHtml, setRawFaqHtml] = useState("");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const tabs = useMemo(() => [
        { label: "Contenu", icon: <DescriptionIcon />, disabled: false },
        { label: "Metadata", icon: <InfoIcon />, disabled: false },
        { label: "Promotion", icon: <CampaignIcon />, disabled: false },
        { label: "Faq", icon: <InfoIcon />, disabled: false },
        { label: "Sources", icon: <SourceIcon />, disabled: false },
        { label: "SEO", icon: <SearchIcon />, disabled: true },
    ], []);

    useEffect(() => {
        const handleContentUpdate = (event) => {
            setCurrentContent(event.detail.content);
        };

        window.addEventListener('articleContentUpdated', handleContentUpdate);
        return () => {
            window.removeEventListener('articleContentUpdated', handleContentUpdate);
        };
    }, []);

    useEffect(() => {
        if (details?.data?.Article) {
            setTitle(details.data.Article.Title || "");
            setDescription(details.data.Article.Description || "");
            setSlug(details.data.Article.Slug || "");
            setTags(details.data.Article.Tags || []);
            setSocialPosts({
                Instagram: details.data.Article.Instagram || "",
                Facebook: details.data.Article.Facebook || "",
                Twitter: details.data.Article.Twitter || "",
                Linkedin: details.data.Article.Linkedin || ""
            });
            if (details.data.Article.Faq) {
                setRawFaqHtml(details.data.Article.Faq);
                const faqContent = extractFaqFromHtml(details.data.Article.Faq);
                details.data.Article.Faq = faqContent;
            }
        }
    }, [details]);

    const handleTabChange = useCallback((event, newValue) => {
        if (!tabs[newValue].disabled) {
            setTabValue(newValue);
        }
    }, [tabs]);

    const handleCopy = useCallback((text) => {
        const content = typeof text === 'object' ? JSON.stringify(text, null, 2) : text;
        navigator.clipboard.writeText(content);
        setSnackbar({ open: true, message: 'Copié avec succès !', severity: 'success' });
    }, []);

    const handleMenuOpen = useCallback((event) => setAnchorEl(event.currentTarget), []);
    const handleMenuClose = useCallback(() => setAnchorEl(null), []);

    const handleSave = useCallback(async (key, value) => {
        setIsSaving(true);
        try {
            await updateData(details.inventory.id, value, key);
            setSnackbar({ open: true, message: 'Sauvegardé avec succès', severity: 'success' });
        } catch (error) {
            console.error('Error saving data:', error);
            setSnackbar({ open: true, message: 'Erreur lors de la sauvegarde', severity: 'error' });
        } finally {
            setIsSaving(false);
        }
    }, [details.inventory.id]);

    const handleAddTag = useCallback((event) => {
        if (event.key === 'Enter' && inputTag.trim() !== '') {
            const newTags = [...tags, inputTag.trim()];
            setTags(newTags);
            handleSave('Tags', newTags);
            setInputTag('');
        }
    }, [inputTag, tags, handleSave]);

    const handleDeleteTag = useCallback((tagToDelete) => {
        const newTags = tags.filter(tag => tag !== tagToDelete);
        setTags(newTags);
        handleSave('Tags', newTags);
    }, [tags, handleSave]);

    const renderMetadataField = useCallback((label, value, setValue, key) => (
        <Box mt={3}>
            <Typography variant="h6" gutterBottom>{label}</Typography>
            <TextField
                fullWidth
                multiline
                rows={key === "Description" ? 3 : key === "Title" ? 2 : 1}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                variant="outlined"
                sx={{ '& .MuiOutlinedInput-root': { backgroundColor: theme.palette.action.hover } }}
            />
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                <Typography variant="caption" color="text.secondary">
                    {value.length} characters
                </Typography>
                <Box>
                    <Button
                        startIcon={<SaveIcon />}
                        onClick={() => handleSave(key, value)}
                        disabled={isSaving}
                        size="small"
                        sx={{ mr: 1 }}
                    >
                        {isSaving ? 'Sauvegarde...' : 'Sauvegarder'}
                    </Button>
                    <Button startIcon={<ContentCopyIcon />} onClick={() => handleCopy(value)} size="small">
                        Copier
                    </Button>
                </Box>
            </Box>
        </Box>
    ), [theme, isSaving, handleSave, handleCopy]);

    const downloadFile = useCallback((content, filename) => {
        const element = document.createElement('a');
        const file = new Blob([content], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = filename;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }, []);

    const updateCurrentContent = useCallback((platform, content) => {
        if (platform === 'Article') {
            setCurrentContent(content);
        }
        return updateData(details.inventory.id, content, platform);
    }, [details.inventory.id]);

    const handleDownload = useCallback((format) => {
        if (details?.data?.Article) {
            let content = '';
            const filename = `article-${details.inventory.id}.${format}`;

            if (format === 'txt') {
                const tempElement = document.createElement('div');
                tempElement.innerHTML = currentContent;
                content = tempElement.textContent || tempElement.innerText || '';
            } else if (format === 'md') {
                const turndownService = new TurndownService({ headingStyle: 'atx', codeBlockStyle: 'fenced' });
                turndownService.use(gfm);
                turndownService.keep(['table', 'thead', 'tbody', 'tr', 'th', 'td']);
                content = turndownService.turndown(currentContent);
            } else if (format === 'html') {
                content = `<!DOCTYPE html>
                    <html lang="fr">
                    <head>
                        <meta charset="UTF-8">
                        <title>${details.data.Article.Title}</title>
                        <style>
                            body { font-family: sans-serif; line-height: 1.6; color: #333; max-width: 800px; margin: 0 auto; padding: 20px; }
                            h1, h2, h3 { color: #2c3e50; }
                            a { color: #3498db; }
                            pre { background-color: #f8f8f8; padding: 10px; border-radius: 5px; }
                        </style>
                    </head>
                    <body>
                        ${currentContent}
                    </body>
                    </html>`;
            }

            downloadFile(content, filename);
        }
        handleMenuClose();
    }, [details, currentContent, downloadFile, handleMenuClose]);

    return (
        <Box sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
        }}>
            <Box sx={{
                width: '100%',
                bgcolor: 'background.paper',
                position: 'sticky',
                top: 1,
                zIndex: 1000,
                boxShadow: '0px 2px 4px 2px rgba(0,0,0,0.2)',
                borderBottom: `1px solid ${theme.palette.divider}`
            }}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    aria-label="scrollable tabs example"
                    sx={{
                        '& .MuiTabs-scrollButtons': {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                    }}
                >
                    {tabs.map((tab, index) => (
                        <Tab
                            key={index}
                            icon={tab.icon}
                            label={!isMobile && tab.label}
                            sx={{
                                minWidth: isMobile ? 'auto' : 120,
                                opacity: tab.disabled ? 0.3 : 1,
                                cursor: tab.disabled ? 'default' : 'pointer',
                            }}
                            title={tab.disabled ? "Feature bientôt disponible" : ""}
                        />
                    ))}
                </Tabs>
            </Box>

            <Box sx={{
                flexGrow: 1,
                overflow: 'auto',
                px: 2,
                pb: 2
            }}>
                {tabValue === 0 && (
                    <>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={3}
                            mt={3}
                        >
                            <Typography variant="h6" sx={{ ml: 2 }}>Aperçu du contenu</Typography>
                            <Box display="flex" alignItems="center">
                                {isMobile ? (
                                    <>
                                        <IconButton onClick={() => handleCopy(details.data.Article.Content)}>
                                            <ContentCopyIcon />
                                        </IconButton>
                                        <IconButton onClick={handleMenuOpen}>
                                            <FileDownloadIcon />
                                        </IconButton>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            variant="outlined"
                                            startIcon={<ContentCopyIcon />}
                                            onClick={() => handleCopy(details.data.Article.Content)}
                                            sx={{ mr: 1 }}
                                        >
                                            Copier HTML
                                        </Button>
                                        <IconButton onClick={handleMenuOpen}>
                                            <FileDownloadIcon />
                                        </IconButton>
                                    </>
                                )}
                            </Box>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={() => handleDownload('html')}>
                                <FileDownloadIcon fontSize="small" sx={{ mr: 1 }} />
                                .html
                            </MenuItem>
                            <MenuItem onClick={() => handleDownload('md')}>
                                <FileDownloadIcon fontSize="small" sx={{ mr: 1 }} />
                                .md
                            </MenuItem>
                            <MenuItem onClick={() => handleDownload('txt')}>
                                <FileDownloadIcon fontSize="small" sx={{ mr: 1 }} />
                                .txt
                            </MenuItem>
                            <MenuItem disabled>
                                <LanguageIcon fontSize="small" sx={{ mr: 1 }} />
                                WordPress
                            </MenuItem>
                        </Menu>
                        <Grid container spacing={2}>
                            {[
                                { label: 'Mots', value: details.inventory.article_word },
                                { label: 'Titres H2', value: details.data.Article.Content.split('<h2').length - 1 }, { label: 'Titres H3', value: details.data.Article.Content.split('<h3').length - 1 },
                                { label: 'Tableaux', value: details.data.Article.Content.split('<table').length - 1 },
                                { label: 'Listes', value: (details.data.Article.Content.split('<ul').length - 1) + (details.data.Article.Content.split('<ol').length - 1) },
                                { label: 'Liens', value: details.data.Article.Content.split('<a ').length - 1 }
                            ].map((item, index) => (
                                <Grid item xs={6} sm={4} key={index}>
                                    <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
                                        <Typography variant="body2" color="text.secondary">{item.label}</Typography>
                                        <Typography variant="h4" color="primary">{item.value}</Typography>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                        <Box sx={{ mt: 2 }}>
                            <TableOfContents content={details.data.Article.Content} onHeadingClick={onHeadingClick} />
                        </Box>
                    </>
                )}
                {tabValue === 1 && (
                    <>
                        {renderMetadataField("Meta Title", title, setTitle, "Title")}
                        {renderMetadataField("Meta Description", description, setDescription, "Description")}
                        {renderMetadataField("Slug", slug, setSlug, "Slug")}

                        <Box mt={3}>
                            <Typography variant="h6" gutterBottom>Tags</Typography>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0.5,
                                    m: 0,
                                    backgroundColor: theme.palette.action.hover,
                                }}
                                component="ul"
                            >
                                {tags.map((tag) => (
                                    <li key={tag}>
                                        <Chip
                                            label={tag}
                                            onDelete={() => handleDeleteTag(tag)}
                                            sx={{ m: 0.5 }}
                                        />
                                    </li>
                                ))}
                                <li>
                                    <TextField
                                        value={inputTag}
                                        onChange={(e) => setInputTag(e.target.value)}
                                        onKeyPress={handleAddTag}
                                        placeholder="Add tag..."
                                        size="small"
                                        sx={{ m: 0.5, width: 150 }}
                                    />
                                </li>
                            </Paper>
                            <Box display="flex" justifyContent="flex-end" mt={1}>
                                <Button
                                    startIcon={<ContentCopyIcon />}
                                    onClick={() => handleCopy(tags.join(', '))}
                                    size="small"
                                >
                                    Copier
                                </Button>
                            </Box>
                        </Box>
                    </>
                )}
                {tabValue === 2 && (
                    <>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} mt={3}>
                            <Typography variant="h6" sx={{ ml: 1 }}>Social Media Posts</Typography>
                        </Box>
                        <SocialPost
                            platform="Instagram"
                            initialContent={socialPosts.Instagram}
                            onSave={(platform, content) => handleSave(platform, content)}
                        />
                        <SocialPost
                            platform="Facebook"
                            initialContent={socialPosts.Facebook}
                            onSave={(platform, content) => handleSave(platform, content)}
                        />
                        <SocialPost
                            platform="Twitter"
                            initialContent={socialPosts.Twitter}
                            onSave={(platform, content) => handleSave(platform, content)}
                        />
                        <SocialPost
                            platform="LinkedIn"
                            initialContent={socialPosts.Linkedin}
                            onSave={(platform, content) => handleSave(platform, content)}
                        />
                    </>
                )}
                {tabValue === 3 && (
                    <Box sx={{ mt: 3, mx: 2 }}>
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mb: 4,
                            pb: 2,
                            borderBottom: '2px solid',
                            borderColor: 'primary.main',
                        }}>
                            <Typography 
                                variant="h5" 
                                sx={{ 
                                    color: 'primary.main',
                                    fontWeight: 'medium'
                                }}
                            >
                                Questions Fréquentes
                            </Typography>
                            <Button
                                variant="outlined"
                                startIcon={<ContentCopyIcon />}
                                onClick={() => handleCopy(rawFaqHtml)}
                                sx={{ ml: 2 }}
                            >
                                Copier HTML
                            </Button>
                        </Box>
                        {details?.data?.Article?.Faq && Object.entries(details.data.Article.Faq).map(([question, reponse], index) => (
                            <Paper 
                                key={index} 
                                elevation={2}
                                sx={{ 
                                    p: 3, 
                                    mb: 3,
                                    borderRadius: 2,
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        transform: 'translateY(-2px)',
                                        boxShadow: 6
                                    }
                                }}
                            >
                                <Typography 
                                    variant="h6" 
                                    gutterBottom 
                                    sx={{ 
                                        color: 'primary.main',
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1
                                    }}
                                >
                                    {question}
                                </Typography>
                                <Typography 
                                    variant="body1"
                                    sx={{ 
                                        mt: 2,
                                        color: 'text.secondary',
                                        lineHeight: 1.7
                                    }}
                                >
                                    {reponse}
                                </Typography>
                            </Paper>
                        ))}
                    </Box>
                )}
                {tabValue === 4 && (
                    <Box sx={{ mt: 3, mx: 2 }}>
                        <Typography 
                            variant="h5" 
                            gutterBottom 
                            sx={{ 
                                mb: 4,
                                pb: 2,
                                borderBottom: '2px solid',
                                borderColor: 'primary.main',
                                color: 'primary.main',
                                fontWeight: 'medium',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1
                            }}
                        >
                            <LinkIcon />
                            Liens Sources
                        </Typography>
                        <Box sx={{ 
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                            gap: 2
                        }}>
                            {details?.data?.Scrap && Object.keys(details.data.Scrap).map((source, index) => (
                                <a 
                                    key={index}
                                    href={source}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            p: 2,
                                            borderRadius: 1,
                                            border: '1px solid',
                                            borderColor: 'divider',
                                            transition: 'all 0.2s ease',
                                            '&:hover': {
                                                borderColor: 'primary.main',
                                                backgroundColor: 'primary.main',
                                                '& .sourceText, & .sourceIcon': {
                                                    color: 'white'
                                                }
                                            }
                                        }}
                                    >
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            gap: 1
                                        }}>
                                            <Typography 
                                                className="sourceText"
                                                sx={{ 
                                                    color: 'text.primary',
                                                    fontSize: '0.9rem',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    maxWidth: 'calc(100% - 30px)',
                                                    transition: 'color 0.2s ease'
                                                }}
                                            >
                                                {source}
                                            </Typography>
                                            <OpenInNewIcon 
                                                className="sourceIcon"
                                                sx={{ 
                                                    fontSize: 16,
                                                    color: 'text.secondary',
                                                    flexShrink: 0,
                                                    transition: 'color 0.2s ease'
                                                }} 
                                            />
                                        </Box>
                                    </Paper>
                                </a>
                            ))}
                        </Box>
                    </Box>
                )}
                {tabValue === 5 && <Typography>Contenu pour l'onglet SEO (à venir)</Typography>}
            </Box>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ContenusArticles;