import React, { useState } from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import CreateProjectModal from './CreateProjectModal';
import { createInventory, generateArticle } from '../services/api';
import { useUser } from '../services/userContext';

const EmptyInventory = ({ onCreateProject, user }) => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { dd, setUser } = useUser();

    const handleCreateProject = async (name, mode, tags) => {
        try {
            const newProject = await createInventory(name, mode, tags);
            console.log('New project created:', newProject);
            navigate(`/edit-project/${newProject.inventory.id}`);
        } catch (error) {
            console.error('Failed to create project:', error);
        }
    };

    const handleCreateStandardProject = async (desireArticle, language) => {
        try {
            const newProject = await createInventory('Loading...', 'Standard', [], desireArticle, language);
            console.log('New project created:', newProject);
            const article = generateArticle(newProject.inventory.id);
            const newCredit = user.credit - 180;

            setUser(prev => ({
                ...prev,
                credit: newCredit
            }));

            setIsModalOpen(false);

        } catch (error) {
            console.error('Failed to create project:', error);
        }
    };

    const handleCreateLightProject = async (desireArticle, language) => {
        try {
            const newProject = await createInventory('Loading...', 'Light', [], desireArticle, language);
            console.log('New project created:', newProject);
            const article = generateArticle(newProject.inventory.id);
            const newCredit = user.credit - 140;

            setUser(prev => ({
                ...prev,
                credit: newCredit
            }));

            setIsModalOpen(false);

        } catch (error) {
            console.error('Failed to create project:', error);
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            py: 8,
            px: 2,
            textAlign: 'center'
        }}>
            <VideoLibraryIcon sx={{ fontSize: 64, color: 'primary.main', mb: 3 }} />
            <Typography variant="h5" gutterBottom>
                Bienvenue sur Magistral AI !
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 4, maxWidth: 500 }}>
                Avant de commencer, vous pouvez regarder notre vidéo d'introduction pour découvrir comment créer votre premier article.
            </Typography>

            <Stack direction="row" spacing={2}>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<VideoLibraryIcon />}
                    onClick={() => navigate('/guide')}
                >
                    Voir les guides
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setIsModalOpen(true)}
                    disabled={false}
                >
                    Créer un projet
                </Button>
            </Stack>

            <CreateProjectModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onCreateProject={handleCreateProject}
                onCreateStandardProject={handleCreateStandardProject}
                onCreateLightProject={handleCreateLightProject}
            />
        </Box>
    );
};

export default EmptyInventory;