import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Select,
    MenuItem,
    Button,
    IconButton,
    Divider,
    useMediaQuery,
    alpha,
    Chip,
    InputAdornment,
    FormControl,
    InputLabel,
    Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useUser } from '../services/userContext';

const CreateProjectModal = ({ open, onClose, onCreateProject, onCreateStandardProject, onCreateLightProject, onCreateVideoProject }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { user } = useUser();

    // États
    const [projectName, setProjectName] = useState('');
    const [mode, setMode] = useState('Standard');
    const [tags, setTags] = useState([]);
    const [currentTag, setCurrentTag] = useState('');
    const [desireArticle, setDesireArticle] = useState('');
    const [language, setLanguage] = useState('French');
    const [videoUrl, setVideoUrl] = useState('');

    // Configuration des modes et leurs coûts
    const modes = {
        Standard: {
            label: 'Standard',
            cost: 180,
            description: 'Article complet et détaillé en 1 clic'
        },
        // Light: {
        //     label: 'Light',
        //     cost: 120,
        //     description: 'Article court et concis en 1 clic'
        // },
        Video: {
            label: 'Video',
            cost: 250,
            description: 'Générer un article à partir d\'une vidéo YouTube'
        },
        Workflow: {
            label: 'Advanced',
            cost: null,
            description: 'Paramètrages avancés, Coût variable'
        }
    };

    const currentCost = modes[mode]?.cost || 0;
    const hasEnoughCredits = user?.credit >= currentCost;
    const isSimplifiedMode = mode === 'Standard' || mode === 'Light' || mode === 'Video';
    const isValid = isSimplifiedMode ? desireArticle.trim().length > 0 || videoUrl.trim().length > 0 : projectName.trim().length > 0;

    // Gestionnaires d'événements
    const handleCreateProject = () => {
        if (!isValid) return;

        if (mode === 'Standard') {
            onCreateStandardProject(desireArticle, language);
        } else if (mode === 'Light') {
            onCreateLightProject(desireArticle, language);
        } else if (mode === 'Video') {
            onCreateVideoProject(videoUrl, language);
        } else {
            onCreateProject(projectName, mode, tags);
        }

        // Réinitialisation du formulaire
        setProjectName('');
        setMode('Standard');
        setTags([]);
        setDesireArticle('');
        setLanguage('French');
        setVideoUrl('');
        onClose();
    };

    const handleTagChange = (event) => {
        const value = event.target.value;
        if (value.endsWith(',')) {
            addCurrentTag();
        } else {
            setCurrentTag(value);
        }
    };

    const handleAddTag = (event) => {
        if (event.key === 'Enter' || event.key === ',') {
            event.preventDefault();
            addCurrentTag();
        }
    };

    const addCurrentTag = () => {
        const trimmedTag = currentTag.trim().replace(/,+$/, '');
        if (trimmedTag !== '' && !tags.includes(trimmedTag)) {
            setTags([...tags, trimmedTag]);
            setCurrentTag('');
        }
    };

    const handleDeleteTag = (tagToDelete) => () => {
        setTags(tags.filter((tag) => tag !== tagToDelete));
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isMobile ? '90%' : 500,
                maxWidth: '100%',
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: 1,
                overflow: 'hidden',
            }}>
                {/* Header */}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 1,
                    bgcolor: alpha(theme.palette.background.paper, 0.9),
                    borderBottom: `1px solid ${theme.palette.divider}`,
                }}>
                    <Typography variant="h6" component="h2" sx={{ ml: 1, color: theme.palette.primary.main }}>
                        {t('Créer un nouveau projet d\'article')}
                    </Typography>
                    <IconButton onClick={onClose} sx={{ color: theme.palette.primary.main }}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Content */}
                <Box sx={{ p: 2, bgcolor: theme.palette.background.default }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2, mt: 2 }}>
                        {/* Mode Selection */}
                        <Box sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            gap: 2,
                            mb: 2
                        }}>
                            <TextField
                                select
                                label={t('Mode IA')}
                                value={mode}
                                onChange={(e) => setMode(e.target.value)}
                                size="medium"
                                fullWidth
                            >
                                <MenuItem disabled sx={{ opacity: 1, fontWeight: 'bold', color: 'text.primary' }}>
                                    Rédaction d'article
                                </MenuItem>
                                {Object.entries(modes).map(([key, { label, cost, description }]) => (
                                    <MenuItem
                                        key={key}
                                        value={key}
                                        sx={{
                                            pl: 4,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            gap: 1
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography>{t(label)}</Typography>
                                            <Typography variant="caption" color="text.secondary">
                                                {description}
                                            </Typography>
                                        </Box>
                                        {cost && (
                                            <Chip
                                                label={`${cost} crédits`}
                                                size="small"
                                                variant="outlined"
                                                sx={{ ml: 'auto' }}
                                            />
                                        )}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>

                        {/* Form Fields */}
                        {isSimplifiedMode ? (
                            <>{mode === 'Video' ? (
                                <TextField
                                    label={t('URL de la vidéo YouTube')}
                                    value={videoUrl}
                                    onChange={(e) => setVideoUrl(e.target.value)}
                                    multiline
                                    rows={5}
                                    fullWidth
                                    error={!videoUrl.trim() && videoUrl.length > 0}
                                    helperText={!videoUrl.trim() && videoUrl.length > 0 ? 'Ce champ est requis' : ''}
                                    placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                                    InputProps={{
                                        sx: {
                                            '& .MuiInputBase-input::placeholder': {
                                                opacity: 0.7,
                                                fontSize: '0.9rem',
                                                fontStyle: 'italic'
                                            },
                                        },
                                    }}
                                />
                            ) : (
                                <TextField
                                    label={t('Quel article souhaitez-vous rédiger ?')}
                                    value={desireArticle}
                                    onChange={(e) => setDesireArticle(e.target.value)}
                                    multiline
                                    rows={5}
                                    fullWidth
                                    error={!desireArticle.trim() && desireArticle.length > 0}
                                    helperText={!desireArticle.trim() && desireArticle.length > 0 ? 'Ce champ est requis' : ''}
                                    placeholder="Exemple: Écrire un article sur la création d'une routine matinale efficace en 5 étapes. Présenter des rituels simples pour augmenter son énergie et sa productivité. Utilisé un ton inspirant et structuré, l'intention principale est 'quelle routine matinale pour être productif ?'"
                                    InputProps={{
                                        sx: {
                                            '& .MuiInputBase-input::placeholder': {
                                                opacity: 0.7,
                                                fontSize: '0.9rem',
                                                fontStyle: 'italic'
                                            },
                                        },
                                    }}
                                />
                            )}
                                <FormControl fullWidth>
                                    <InputLabel>Language</InputLabel>
                                    <Select
                                        value={language}
                                        onChange={(e) => setLanguage(e.target.value)}
                                        label="Language"
                                    >
                                        <MenuItem value="French">Français</MenuItem>
                                        <MenuItem value="English">Anglais</MenuItem>
                                        <MenuItem value="Spanish">Espagnol</MenuItem>
                                        <MenuItem value="Italian">Italien</MenuItem>
                                        <MenuItem value="German">Allemand</MenuItem>
                                        <MenuItem value="Portuguese">Portugais</MenuItem>
                                    </Select>
                                </FormControl>
                            </>) : (
                            <><TextField
                                label={t('Nom du projet')}
                                value={projectName}
                                onChange={(e) => setProjectName(e.target.value)}
                                inputProps={{ maxLength: 50 }}
                                size="medium"
                                fullWidth
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{`${projectName.length}/50`}</InputAdornment>,
                                }}
                                error={!projectName.trim() && projectName.length > 0}
                                helperText={!projectName.trim() && projectName.length > 0 ? t('Le nom du projet est requis') : ''}
                            />
                                <TextField
                                    label={t('Tags du projet')}
                                    value={currentTag}
                                    onChange={handleTagChange}
                                    onKeyDown={handleAddTag}
                                    size="medium"
                                    fullWidth
                                    placeholder={t('Appuyez sur Entrée pour ajouter un tag')}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        sx: {
                                            '& input::placeholder': {
                                                opacity: 0.7,
                                                color: theme.palette.text.secondary,
                                            },
                                        },
                                    }}
                                />
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {tags.map((tag) => (
                                        <Chip
                                            key={tag}
                                            label={tag}
                                            onDelete={handleDeleteTag(tag)}
                                            color="primary"
                                            variant="outlined"
                                        />
                                    ))}
                                </Box>
                            </>
                        )}
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    {/* Footer */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: isMobile ? 'stretch' : 'center',
                        gap: isMobile ? 2 : 0
                    }}>
                        {isSimplifiedMode && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Chip
                                    label={`Coût : ${currentCost} crédits`}
                                    color="primary"
                                    variant="outlined"
                                    sx={{
                                        borderRadius: '8px',
                                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                                        '& .MuiChip-label': {
                                            fontWeight: 'bold'
                                        }
                                    }}
                                />
                            </Box>
                        )}
                        <Tooltip
                            title={(!hasEnoughCredits && isSimplifiedMode) ?
                                "Vous n'avez pas assez de crédits pour générer un article. Veuillez recharger votre compte." :
                                ""}
                            placement="top"
                        >
                            <span>
                                <Button
                                    variant="contained"
                                    onClick={handleCreateProject}
                                    fullWidth={isMobile}
                                    disabled={!isValid || !hasEnoughCredits}
                                    sx={{
                                        bgcolor: hasEnoughCredits ? theme.palette.primary.main : 'error.main',
                                        '&:hover': {
                                            bgcolor: hasEnoughCredits ? theme.palette.primary.dark : 'error.dark',
                                        },
                                        '&.Mui-disabled': {
                                            bgcolor: alpha(theme.palette.primary.main, 0.5),
                                            color: theme.palette.primary.contrastText
                                        },
                                        ...(isMobile ? {} : { minWidth: '200px' })
                                    }}
                                    startIcon={hasEnoughCredits ? null : <AccountBalanceWalletIcon />}
                                >
                                    {isSimplifiedMode ? (
                                        !hasEnoughCredits
                                            ? t("Crédits insuffisants")
                                            : t('Générer un article')
                                    ) : (
                                        t('Créer un projet')
                                    )}
                                </Button>
                            </span>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default CreateProjectModal;